import React from 'react';
import AppLayout from '../components/AppLayout';
import MainBanner from '../components/MainBanner';
import { product } from '../data/product';
import styled from 'styled-components';

const Home = () => {
	console.log(product);
	return (
		<AppLayout>
			<HomeWrap>
				<MainBanner />
				<h1>고객님이 가장 많이 선택한 상품</h1>
				<div className='sub__title'>
					1993년부터 고객님이 원하시는 나무상자를 맞춤제작 하고있습니다.
				</div>
				<div className='product__list'>
					{product.map((item, index) => (
						<CardWrap key={item.id + item.title}>
							<div className='product__img-wrap'>
								<img src={item.src} alt={item.title} />
							</div>
							<div className='product__script-wrap'>
								<h2>{item.title}</h2>
							</div>
						</CardWrap>
					))}
				</div>
			</HomeWrap>
		</AppLayout>
	);
};

export default Home;

const CardWrap = styled.div`
	.product__img-wrap {
		background-color: #f0f0f0;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 340px;
		max-height: 340px;
	}
	.product__img-wrap > img {
		width: 65%;
	}
	.product__script-wrap {
		padding: 20px 8px 32px;
	}

	.product__script-wrap h2 {
		font-weight: 600;
		font-size: 16px;
		text-align: center;
	}
	@media (max-width: 768px) {
		.product__img-wrap {
			min-height: 260px;
		}
		.product__img-wrap > img {
			width: 70%;
		}
	}
	@media (max-width: 576px) {
		.product__img-wrap {
			min-height: 160px;
		}
		.product__img-wrap > img {
			width: 72%;
		}
		.product__script-wrap {
			padding: 18px 4px 24px;
		}
		.product__script-wrap h2 {
			font-weight: 600;
			font-size: 15px;
			text-align: center;
		}
	}
`;

const HomeWrap = styled.div`
	.product__list {
		padding: 24px;
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		grid-gap: 28px;
		margin-bottom: 32px;
	}

	h1 {
		padding-top: 38px;
		text-align: center;
		font-weight: 600;
		font-size: 28px;
		margin-bottom: 4px;
	}

	.sub__title {
		text-align: center;
		font-size: 18px;
		margin-bottom: 20px;
	}

	@media (max-width: 768px) {
		.product__list {
			padding: 20px;
			grid-template-columns: repeat(2, 1fr);
			grid-gap: 24px;
		}
	}

	@media (max-width: 576px) {
		h1 {
			font-size: 26px;
		}
		.product__list {
			padding: 14px;
			grid-gap: 14px;
		}
	}
`;
