import React, { memo } from 'react';
import styled from 'styled-components';
import { InfoOutlined } from '@ant-design/icons';

const FooterWrap = styled.footer`
	background-color: #f0f0f0;
	overflow: hidden;
	box-sizing: border-box;
	> .footer {
		width: 100%;
		margin: 0 auto;
		padding: 70px 0;
		border-top: 1px solid #eee;
		font-size: 17px;
		& h2 {
			font-weight: 600;
			font-size: 24px;
			margin-bottom: 15px;
			text-align: center;
		}

		& div {
			text-align: center;
		}

		& .footer_phone a {
			font-weight: 600;
			font-size: 22px;
		}
	}
	@media (max-width: 576px) {
		.footer {
			width: 100%;
			padding: 50px 10px;
			font-size: 15px;
		}
	}
`;

const Footer = memo(() => {
	return (
		<FooterWrap>
			<div className='footer'>
				<h2>성진 오동</h2>
				<div className='footer_phone'>
					<a href='tel:031-581-1757'>031-581-1757</a>
					&nbsp;&nbsp;<strong>/</strong>&nbsp;&nbsp;
					<a href='tel:010-5363-1757'>010-5363-1757</a>
				</div>
				<div>AM 09:00 ~ PM 07:00&nbsp; WEEK ON / HOLIDAY ON</div>
				<div>
					국민 277001-04-003905 이승희 <br />
					SUNG JIN PAULOWNIA
				</div>
				<div>
					<p>
						법인명 (상호) : (주)성진오동 <InfoOutlined /> 사업자등록번호 :
						132-10-65430 <InfoOutlined /> 대표 : 이승희
						<br />
						주소 : 경기 가평군 청평면 버들숲로8번길 16-23 <br />
						대표번호 : 031-581-1757
						<InfoOutlined />
						팩스번호 : 031-582-9723 <InfoOutlined /> 메일 :{' '}
						<a href='mailto:tjdwls505@naver.com'>tjdwls505@naver.com</a>
					</p>
				</div>
			</div>
		</FooterWrap>
	);
});

export default Footer;
