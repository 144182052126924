import React, { memo } from 'react';
import styled from 'styled-components';
import { PhoneFilled } from '@ant-design/icons';

const FixedButtonWrap = styled.div`
	position: fixed;
	bottom: 40px;
	right: 24px;
	background-color: #fff;
	border: 1px solid #ebedf0;
	padding: 10px;
	border-radius: 30px;
	box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
	z-index: 999;

	& i {
		padding: 8px;
		border-radius: 100%;
		background-color: #382c1e;
		color: #fff;
		margin-right: 5px;
	}
	span {
		margin-right: 5px;
	}
	@media (max-width: 576px) {
		& {
			display: unset;
		}
	}
`;

const FixedButton = memo(() => {
	return (
		<FixedButtonWrap>
			<a href='tel:010-5363-1757'>
				<PhoneFilled />
				전화 상담
			</a>
		</FixedButtonWrap>
	);
});

export default FixedButton;
