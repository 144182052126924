import React, { useEffect, useState, useCallback, memo } from 'react';
import Menu from './Menu';
import Footer from './Footer';
import FixedButton from './FixedButton';

const AppLayout = memo(({ children }) => {
	return (
		<div>
			<Menu />
			<>{children}</>
			<FixedButton />
			<Footer />
		</div>
	);
});

export default AppLayout;
