export const product = [
	{
		id: 1,
		title: '잣 나무상자',
		src: '/assets/product/잣상자01.png',
		description: '나무상자',
	},
	{
		id: 2,
		title: '곶감 나무상자',
		src: '/assets/product/곶감상자01.png',
		description: '곶감상자',
	},
	{
		id: 2,
		title: '장뇌삼 나무상자',
		src: '/assets/product/장뇌삼상자01.png',
		description: '장뇌삼상자',
	},
	{
		id: 3,
		title: '곶감 나무상자',
		src: '/assets/product/곶감상자01.png',
		description: '곶감상자',
	},
	{
		id: 4,
		title: '한우 나무상자',
		src: '/assets/product/한우상자01.png',
		description: '한우상자',
	},
	{
		id: 5,
		title: '소면 나무상자',
		src: '/assets/product/소면상자01.png',
		description: '소면상자',
	},
	{
		id: 6,
		title: '수제한차 나무상자',
		src: '/assets/product/수제한차상자01.png',
		description: '수제한차상자',
	},
	{
		id: 7,
		title: '맞춤제작 나무상자',
		src: '/assets/product/맞춤상자01.png',
		description: '맞춤한차상자',
	},
	{
		id: 8,
		title: '미닫이 나무상자',
		src: '/assets/product/미닫이상자01.png',
		description: '미닫이상자',
	},
	{
		id: 9,
		title: '창틀 나무상자',
		src: '/assets/product/창틀상자01.png',
		description: '창틅상자',
	},
	{
		id: 10,
		title: '미니 나무상자',
		src: '/assets/product/미니상자01.png',
		description: '미니상자',
	},
	{
		id: 11,
		title: '유골함 나무상자',
		src: '/assets/product/유골함상자01.png',
		description: '유골함상자',
	},
];
