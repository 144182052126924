import React, { useEffect, useState, useCallback, memo } from 'react';
import styled from 'styled-components';
import { Drawer, Divider } from 'antd';
import { Link } from 'react-router-dom';
import { MenuOutlined } from '@ant-design/icons';

const MenuWrap = styled.nav`
	width: 100%;
	overflow: hidden;
	text-align: center;
	border-bottom: 3px solid #744b27;
	background-color: #fff;

	&.top_fixed {
		z-index: 502;
		position: fixed;
		left: 0px;
		right: 0px;
		top: 0px;
	}

	& > div {
		box-sizing: border-box;
		padding: 25px 40px 22px;
		max-width: 1200px;
		width: 100%;
		margin: 0 auto;
		display: flex;
		justify-content: space-between;
	}

	& ul.menu_left {
		float: left;
	}
	& ul.menu_rigth {
		float: right;
	}
	& ul li {
		display: inline-block;
		vertical-align: middle;
	}
	& ul li a {
		line-height: 26px;
		font-weight: 700;
		display: inline-block;
		margin: 10px 12px 0;
		padding: 14px 6px 8px;
		font-size: 18px;
		color: #424242;
	}
	& ul li a:hover,
	& ul li span:hover {
		color: #99b53b;
	}
	& .logo_wrap {
		padding: 0;
		margin: 8px 30px 0 0;
		width: 140px;
		float: left;
	}
	& .logo_wrap a img {
		vertical-align: middle;
	}

	& .menu_rigth li:last-child a {
		padding-right: 0;
		margin-right: 0;
	}
	& .drawer_wrap {
		display: none;
	}
	.logo_phone > strong {
		font-weight: 600;
	}
	.logo_phone > a {
		font-size: 18px;
	}
	@media (max-width: 576px) {
		&.top_fixed {
			height: 60px;
		}
		& > div {
			width: 100%;
			min-width: 370px;
			padding: 4px 10px;
			display: flex;
			align-items: center;
			justify-content: center;
		}
		& ul.menu_left {
			float: right;
		}
		& ul.menu_rigth {
			display: none;
		}
		& .logo_wrap {
			width: 110px;
			margin: 10px 0 0 0;
		}
		& ul li a {
			margin: 0 0 0 8px;
			padding: 12px 6px 10px;
			font-size: 16px;
		}
		& ul li a:last-child {
			padding-right: 0px;
		}
		& .drawer_wrap {
			display: unset;
			float: left;
			font-size: 20px;
			padding: 10px 6px 10px 0;
			font-weight: 600;
			margin-right: 5px;
		}
		.logo_phone {
			display: none;
		}
	}
`;

const Menu = memo(() => {
	const [drawer, setDrawer] = useState(false);

	const onDrawer = useCallback(() => {
		setDrawer((prev) => !prev);
	}, []);

	return (
		<>
			<MenuWrap className='top_fixed'>
				<div>
					{/* <span className='drawer_wrap' onClick={onDrawer}>
						<MenuOutlined />
					</span> */}
					<div className='logo_wrap'>
						<Link to='/'>
							<img
								alt='나무상자 제작 성진오동 로고'
								src={`https://sungjincase.s3.ap-northeast-2.amazonaws.com/original/sungjin_logo4.png`}
								width='100%'
							/>
						</Link>
					</div>
					<div className='logo_phone'>
						<strong>전화상담</strong> <br />
						<a href='tel:010-5363-1757'>010-5363-1757</a>
					</div>
					{/* <ul className='menu_left'>
						<li>
							<Link to='/intro'>회사소개</Link>
						</li>
						<li>
							<Link to='/product'>
								<a>상품소개</a>
							</Link>
						</li>
					</ul> */}
				</div>
				{/* <Drawer
					title='성진오동'
					placement='left'
					closable={false}
					onClose={onDrawer}
					visible={drawer}
				>
					<Divider />
					<ul>
						<li>
							<Link href='/intro'>
								<a>회사소개</a>
							</Link>
						</li>
						<li>
							<Link href='/product'>
								<a>상품소개</a>
							</Link>
						</li>
						<li>
							<Link href='/inquiry'>
								<a>상품문의</a>
							</Link>
						</li>
					</ul>
				</Drawer> */}
			</MenuWrap>
			<div className='menu_height'></div>
		</>
	);
});

export default Menu;
