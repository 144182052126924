import React, { memo } from 'react';
import styled from 'styled-components';

import MB from '../assets/img/mobile_banner.png';
import PB from '../assets/img/pc_banner.png';

const MainBannerWrap = styled.div`
	text-align: center;
	min-width: 100%;

	& .pc_banner img {
		width: 100%;
		vertical-align: top;
	}

	& .pc_banner ul li {
		display: inline-block;
		margin: 30px 15px 0 15px;
	}
	& .pc_banner ul li img {
		width: unset;
		min-width: unset;
		vertical-align: top;
	}
	& .pc_banner {
		display: block;
	}
	& .mobile_banner {
		display: none;
	}
	@media (max-width: 576px) {
		& {
			min-width: 375px;
		}
		& img.mobile_banner {
			width: 100%;
			display: block;
		}
		& .pc_banner {
			display: none;
		}
	}
`;

const MainBanner = memo(() => {
	return (
		<>
			<MainBannerWrap>
				<div className='pc_banner'>
					<img alt='성진오동 나무상자 PC 배너' src={PB} />
					{/* <ul>
									<li>
										<Link href='/intro'>
											<a>
												<img
													src={`https://sungjincase.s3.ap-northeast-2.amazonaws.com/original/sungjin_intro.png`}
												/>
											</a>
										</Link>
									</li>
									<li>
										<Link href='/product'>
											<a>
												<img
													src={`https://sungjincase.s3.ap-northeast-2.amazonaws.com/original/sungjin_product.png`}
												/>
											</a>
										</Link>
									</li>
									<li>
										<Link href='/inquiry'>
											<a>
												<img
													src={`https://sungjincase.s3.ap-northeast-2.amazonaws.com/original/sungjin_inquiry01.png`}
												/>
											</a>
										</Link>
									</li>
									<li>
										<a href='tel:010-5363-1757'>
											<img
												src={`https://sungjincase.s3.ap-northeast-2.amazonaws.com/original/sungjin_inquiry02.png`}
											/>
										</a>
									</li>
									<li>
										<img
											src={`https://sungjincase.s3.ap-northeast-2.amazonaws.com/original/sungjin_inquiry03.png`}
										/>
									</li>
								</ul> */}
				</div>
				<img
					className='mobile_banner'
					alt='성진오동 나무상자 Mobile 배너'
					src={MB}
				/>
			</MainBannerWrap>
		</>
	);
});

export default MainBanner;
